
"use client";
import Intercom from '@intercom/messenger-js-sdk';

const IntercomWidget = ({ user }) => {
  Intercom({
    app_id: 'omddr5w6',
    user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: user.fname, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: Math.floor(new Date(user.createdAt).getTime() / 1000), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  return <></>;
};

export default IntercomWidget;
