"use client";

import React, { useEffect, useState } from "react";

export default function ExternalBrowser({ links, child }) {
  const [isWebView, setIsWebView] = useState(false);
  const link = links;
  const fallBackLink = links;
  useEffect(() => {
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // List of common webview identifiers
      const webViewIdentifiers = [
        "FBAN", // Facebook app
        "FBAV", // Facebook app
        "Instagram", // Instagram app
        // "Twitter", // Twitter app
        // "Line", // LINE app
        "Messenger", // Facebook Messenger
        // "WebView", // Generic WebView
        // "wv", // Generic WebView on Android
        // "Android.*Version/.+Chrome/[.0-9]* Mobile Safari/[.0-9]*", // Chrome on Android
        // "iPhone", // iOS
        // "iPad", // iOS
        // "Telegram", // Telegram WebView
      ];

      const isWebView = webViewIdentifiers.some((identifier) =>
        new RegExp(identifier, "i").test(userAgent)
      );
      // setIsWebView((_prev) => {
      //   openInExternalBrowser(isWebView);
      //   return isWebView;
      // });
    }
  }, []);

  const openInExternalBrowser = (isWebViewB) => {
    const url = `${fallBackLink}`;
    if (isWebViewB) {
      if (/Android/i.test(navigator.userAgent)) {
        const browserIntents = [
          `intent://${url.replace(/^https?:\/\//, "")}#Intent;scheme=http;end;`, // Default intent
          `intent://${url.replace(
            /^https?:\/\//,
            ""
          )}#Intent;scheme=http;package=com.android.chrome;end;`, // Chrome
          `intent://${url.replace(
            /^https?:\/\//,
            ""
          )}#Intent;scheme=https;package=org.mozilla.firefox;end;`, // Firefox
          `intent://${url.replace(
            /^https?:\/\//,
            ""
          )}#Intent;scheme=https;package=com.opera.browser;end;`, // Opera
          `intent://${url.replace(
            /^https?:\/\//,
            ""
          )}#Intent;scheme=https;package=com.microsoft.emmx;end;`, // Edge
        ];

        for (let intentUrl of browserIntents) {
          try {
            window.location.href = intentUrl;
            break;
          } catch (e) {
            console.log(`Failed to open with ${intentUrl}`, e);
          }
        }

        setTimeout(() => {
          window.open(url);
        }, 500);
      } else if (/iPhone|iPad/i.test(navigator.userAgent)) {
        // Attempt to force open in Safari
        // window.location.href = `googlechrome://${encodeURIComponent(url)}`;
        window.location.replace(`x-safari-${links}`);
        // window.open("x-safari-https://tickitco.page.link/ng84");
        setTimeout(() => {
          window.location.href = link;
        }, 3000);
      } else {
        window.location.href = link;
      }
    } else {
      console.log("here");
      console.log("Opening Google...", link);
      // window.open(`${link}`, "_self");
    }
  };
  // const [isSet, setS] = useState(false);
  // const isInstagramWebView = () => {
  //   return navigator.userAgent.includes("Instagram");
  // };

  // const openLinkExternally = (url) => {
  //   if (isInstagramWebView()) {
  //     if (/Android/i.test(navigator.userAgent)) {
  //       setS(true);
  //       const browserIntents = [
  //         `intent://${url.replace(/^https?:\/\//, "")}#Intent;scheme=http;end;`, // Default intent
  //         `intent://${url.replace(
  //           /^https?:\/\//,
  //           ""
  //         )}#Intent;scheme=http;package=com.android.chrome;end;`, // Chrome
  //         `intent://${url.replace(
  //           /^https?:\/\//,
  //           ""
  //         )}#Intent;scheme=https;package=org.mozilla.firefox;end;`, // Firefox
  //         `intent://${url.replace(
  //           /^https?:\/\//,
  //           ""
  //         )}#Intent;scheme=https;package=com.opera.browser;end;`, // Opera
  //         `intent://${url.replace(
  //           /^https?:\/\//,
  //           ""
  //         )}#Intent;scheme=https;package=com.microsoft.emmx;end;`, // Edge
  //       ];

  //       for (let intentUrl of browserIntents) {
  //         try {
  //           window.location.href = intentUrl;
  //           break;
  //         } catch (e) {
  //           console.log(`Failed to open with ${intentUrl}`, e);
  //         }
  //       }

  //       // setTimeout(() => {
  //       window.open(url);
  //       // }, 500);
  //     } else {
  //       window.open(url);
  //       // window.location.href = url;
  //     }
  //   } else if (/iPhone|iPad/i.test(navigator.userAgent)) {
  //     // <a href="googlechrome://tickit.co"> Redirect to Chrome browser ios</a>
  //     // <br />
  //     // <a href="x-safari-https://tickit.co">Redirect to Safari browser</a>
  //     window.open("x-safari-https://tickitco.page.link/ng84");
  //     setS(true);
  //   } else {
  //     window.open("https://tickitco.page.link/ng84");
  //     setS(true);
  //   }
  // };

  // useEffect(() => {
  //   const targetUrl = "https://tickitco.page.link/ng84"; // The URL you want to open
  //   openLinkExternally(targetUrl);
  // }, []); // Empty dependency array to run only on component mount

  return <body>{child}</body>;
}
